import { createEntityFeature, EntityFeatureState, LoadingState } from '@mkp/shared/util-state';
import { AccountMembership } from '@mkp/account-membership/data-access';
import { Action, createReducer, on } from '@ngrx/store';
import { accountMembershipActions } from '@mkp/account-membership/actions';
import { authActions, boAuthActions } from '@mkp/auth/actions';
import { legalEntityDetailsActions } from '@mkp/legal-entity/actions';

type State = EntityFeatureState<AccountMembership, { message: string }>;

const name = 'accountMembership';
const { featureKey, initialState, updaters, selectors } = createEntityFeature<
  State,
  'accountMembership'
>(name);

const _reducer = createReducer(
  { ...initialState },
  on(accountMembershipActions.reset, () => ({ ...updaters.reset() })),
  on(accountMembershipActions.loadAccountMembershipsSuccess, (state, { accountMemberships }) =>
    updaters.getListSuccess(state, { entities: accountMemberships })
  ),
  on(
    legalEntityDetailsActions.accountMembershipsLoadSuccess,
    (state, { accountMemberships, totalCount }) => ({
      ...updaters.getListSuccess(state, { entities: accountMemberships }),
      totalCount,
    })
  ),
  on(
    authActions.loadActiveAccountMembershipsSuccess,
    (state, { accountMemberships, totalCount }) => ({
      ...updaters.getListSuccess(state, { entities: accountMemberships }),
      selectedId: accountMemberships.length === 1 ? accountMemberships[0].id : null,
      totalCount,
    })
  ),
  on(
    accountMembershipActions.loadMoreAccountMembershipsSuccess,
    (state, { accountMemberships, filter, _links, totalCount }) => ({
      ...updaters.getPaginatedListSuccess(state, {
        entities: accountMemberships,
        filter,
        _links,
        totalCount,
      }),
    })
  ),
  on(accountMembershipActions.createAccountMembershipSuccess, (state, { accountMembership }) => ({
    ...updaters.createSuccess(state, { entity: accountMembership }),
  })),
  on(accountMembershipActions.patchAccountMembershipStateSuccess, (state, updateEntity) => ({
    ...updaters.updateSuccess(state, { updateEntity }),
  })),
  on(
    accountMembershipActions.createAccountMembershipFailure,
    accountMembershipActions.loadAccountMembershipsFailure,
    accountMembershipActions.patchAccountMembershipStateFailure,
    legalEntityDetailsActions.accountMembershipsLoadFailure,
    updaters.setError
  ),
  on(
    accountMembershipActions.loadAccountMemberships,
    accountMembershipActions.loadMoreAccountMemberships,
    accountMembershipActions.createAccountMembership,
    accountMembershipActions.patchAccountMembershipState,
    legalEntityDetailsActions.accountsLoadSuccess,
    authActions.userIsVerified,
    updaters.setLoading
  ),
  on(authActions.loadActiveAccountMembershipsFailed, (state) => ({
    ...state,
    fetchState: LoadingState.LOADED,
  })),
  on(authActions.workspaceSelected, (state, { accountMembershipId }) => ({
    ...state,
    selectedId: accountMembershipId,
  })),
  on(boAuthActions.workspaceUnselected, (state) => ({
    ...state,
    selectedId: null,
  }))
);

function reducer(state: State | undefined, action: Action): State {
  return _reducer(state, action);
}

export const accountMembershipFeature = { featureKey, initialState, reducer, selectors, name };
