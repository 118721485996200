import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { selectSelectedAccount } from '@mkp/account/state';
import { Store } from '@ngrx/store';
import { Observable, filter, of, switchMap, take } from 'rxjs';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { selectWorkspaceSelecting } from '@mkp/auth/state';
import { concatLatestFrom } from '@ngrx/operators';
import { LoadingState } from '@mkp/shared/util-state';

// This Guard doesn't handle redirection when unselecting an account
// Refer to AccountProtectionService instead for this feature
@Injectable({
  providedIn: 'root',
})
export class BoAccountSelectedGuard {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectWorkspaceSelecting).pipe(
      concatLatestFrom(() => this.store.select(selectIsBoUser)),
      filter(([state]) => state === LoadingState.LOADED),
      take(1),
      switchMap(([_, isBoUser]) =>
        isBoUser
          ? this.store.select(selectSelectedAccount).pipe(
              take(1),
              switchMap((account) => (!account ? this.navigateToFallbackRoute(route) : of(true)))
            )
          : of(true)
      )
    );
  }

  navigateToFallbackRoute(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.router.navigate([getFallbackRoute(route)]);
    return of(false);
  }
}

export const getFallbackRoute = (route: ActivatedRouteSnapshot): string =>
  route.data['fallbackRoute'] ?? AppConfig.routes.home;
