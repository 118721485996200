import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { selectAccounts, selectSelectedAccount } from '@mkp/account/state';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { concatLatestFrom } from '@ngrx/operators';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { isNotNull } from './guard-helpers';

export function selectedAccountGuard(): Observable<true | UrlTree> {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectSelectedAccount).pipe(
    concatLatestFrom(() => [
      store.select(selectIsBoUser).pipe(filter(isNotNull)),
      store.select(selectAccounts).pipe(filter(isNotNull))
    ]),
    filter(([, isBoUser, accounts]) => !!accounts.length || isBoUser),
    map(([account, isBoUser]) => !!account || getUrlTree(router, isBoUser))
  );
}

const getUrlTree = (router: Router, isBoUser: boolean): UrlTree =>
  router.createUrlTree([
    isBoUser ? AppConfig.routes.dashboard : AppConfig.routes.selectAccountMembership,
  ]);
