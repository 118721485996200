import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import { getMessageError } from '@app/core/models';
import { BoDashboardService } from '@app/core/services/bo-dashboard.service';
import { vacancyApiActions } from '@app/features/vacancy/store/actions/vacancy-api.actions';
import {
  AccountResource,
  addLegalEntityToAccountDto,
  addLegalEntityToAccountsDto,
  mapAccountDtosToAccounts,
} from '@mkp/account/data-access';
import { accountApiActions } from '@mkp/account/data-access/actions';
import { claimManagementActions, legalEntityDetailsActions } from '@mkp/legal-entity/actions';
import { LegalEntityResource } from '@mkp/legal-entity/data-access';
import { SnackbarService } from '@mkp/shared/ui-library';
import { BoDashboardActions } from '@mkp/user/feature-bo-dashboard/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { userSelectors } from '@user/store/selectors';
import {
  OperatorFunction,
  catchError,
  filter,
  map,
  mergeMap,
  of,
  pipe,
  switchMap,
  tap,
} from 'rxjs';
import { boAuthActions } from '@mkp/auth/actions';
import { QueryParams } from '@mkp/shared/data-access';
import { selectAccountById } from '@mkp/account/state';

export const addAccount = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityResource)
  ) =>
    actions$.pipe(
      ofType(accountApiActions.addAccount),
      switchMap(({ legalEntityId, name }) =>
        accountResource.add({ legalEntityId, name }).pipe(
          addLegalEntityToAccountDto(legalEntityResource),
          map((account) => accountApiActions.addAccountSuccess({ account })),
          catchError(() => {
            return of(
              accountApiActions.addAccountFailure({
                error: { message: name },
              })
            );
          })
        )
      )
    ),
  { functional: true }
);

export const addAccountSuccess = createEffect(
  (actions$ = inject(Actions), snackbarService = inject(SnackbarService)) =>
    actions$.pipe(
      ofType(accountApiActions.addAccountSuccess),
      tap(({ account }) => {
        snackbarService.show('ADD_WORKSPACE.SNACKBAR.SUCCESS', {
          translationParams: { name: account.name },
        });
      }),
      map(({ account }) =>
        claimManagementActions.loadAccountFromSelectedLegalEntity({
          legalEntityId: account.legalEntity.id,
        })
      )
    ),
  { functional: true }
);

export const addAccountFailure = createEffect(
  (actions$ = inject(Actions), snackbarService = inject(SnackbarService)) =>
    actions$.pipe(
      ofType(accountApiActions.addAccountFailure),
      tap(({ error: { message } }) => {
        snackbarService.showError('ADD_WORKSPACE.SNACKBAR.ERROR', {
          translationParams: { name: message },
        });
      })
    ),
  { functional: true, dispatch: false }
);

// on the vacancy-list, BO users have one more column than regular user: the name of the legal-entity
// so we need to download the accounts linked to these vacancy only for BO user
export const fetchAccountsFromVacancies = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityResource)
  ) =>
    actions$.pipe(
      ofType(vacancyApiActions.listVacanciesSuccess, vacancyApiActions.loadMoreVacanciesSuccess),
      concatLatestFrom(() => store.select(userSelectors.selectIsBoUser)),
      filter(([{ vacancies }, isBoUser]) => !!vacancies.length && !!isBoUser),
      map(([{ vacancies }]) => vacancies.map(({ accountId }) => accountId)),
      fetchAccountsFromAccountIds(
        accountResource,
        legalEntityResource,
        'fetchAccountsFromVacancies'
      )
    ),
  { functional: true }
);

export const fetchLegalEntityAccounts = createEffect(
  (actions$ = inject(Actions), accountResource = inject(AccountResource)) =>
    actions$.pipe(
      ofType(
        legalEntityDetailsActions.legalEntityLoadSuccess,
        legalEntityDetailsActions.legalEntitySetSelected,
        legalEntityDetailsActions.loadAccounts
      ),
      switchMap(({ legalEntity }) =>
        accountResource.getWithQuery(getLegalEntityAccountsQueryParams(legalEntity.id)).pipe(
          map(({ _embedded: { results: accounts } }) =>
            mapAccountDtosToAccounts(accounts, [legalEntity])
          ),
          filter(Boolean),
          map((accounts) => legalEntityDetailsActions.accountsLoadSuccess({ accounts })),
          catchError((error: unknown) =>
            of(
              legalEntityDetailsActions.accountsLoadFailure({
                error: getMessageError(error, 'fetchLegalEntityAccounts'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

export const fetchAndSetAccount = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityResource),
    router = inject(Router)
  ) =>
    actions$.pipe(
      ofType(boAuthActions.loadWorkspace),
      concatLatestFrom(({ accountId }) => store.select(selectAccountById(accountId))),
      switchMap(([{ accountId }, maybeAccount]) =>
        maybeAccount
          ? of(boAuthActions.loadWorkspaceAccountLoadSuccess({ accounts: [maybeAccount] }))
          : accountResource.getById(accountId).pipe(
              filter(Boolean),
              addLegalEntityToAccountDto(legalEntityResource),
              map((account) =>
                boAuthActions.loadWorkspaceAccountLoadSuccess({ accounts: [account] })
              ),
              catchError((error: unknown) => {
                router.navigate([`/${AppConfig.routes.dashboard}`]);
                return of(
                  boAuthActions.loadWorkspaceAccountLoadError({
                    error: getMessageError(error, 'fetchAndSetAccount'),
                  })
                );
              })
            )
      )
    ),
  { functional: true }
);

// BO users have access to the claim-management page, that lists the legal-entities
// on this page they have an "Enter company" button. When they click it, we need to fetch the account and select it
export const fetchAccountFromSelectedLegalEntity = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityResource)
  ) =>
    actions$.pipe(
      ofType(claimManagementActions.loadAccountFromSelectedLegalEntity),
      switchMap(({ legalEntityId }) =>
        accountResource.getWithQuery(getLegalEntityAccountsQueryParams(legalEntityId)).pipe(
          map(({ _embedded: { results: accounts } }) => accounts[0]),
          filter(Boolean),
          addLegalEntityToAccountDto(legalEntityResource),
          map((account) =>
            accountApiActions.loadAccountByLegalEntityExternalIdSuccess({
              account,
              selectedAccountId: account.id,
            })
          ),
          catchError((error: unknown) =>
            of(
              accountApiActions.loadAccountByLegalEntityExternalIdFailure({
                error: getMessageError(error, 'fetchAccountFromSelectedLegalEntity'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

// when selecting a company from the claim-management page: we should add it to the bo-dashboard state
// IMPR: bo-dashboard should be a local-store and listen to this action
export const addAccountToBoDashboardWhenSelectedOnClaimManagement = createEffect(
  (actions$ = inject(Actions), boDashboardService = inject(BoDashboardService)) =>
    actions$.pipe(
      ofType(claimManagementActions.selectLegalEntity),
      ofType(accountApiActions.loadAccountByLegalEntityExternalIdSuccess),
      tap(({ account }) => boDashboardService.addAccount(account))
    ),
  { functional: true, dispatch: false }
);

// we have a "last selected companies" feature in two places for BO user:
// - on the BO dashboard (enter)
// - in the account selector typeahead (initDropdown)
export const loadLastSelectedAccountsForBoUser = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityResource)
  ) =>
    actions$.pipe(
      ofType(BoDashboardActions.enter, BoDashboardActions.initDropdown),
      filter(({ accountIds }) => accountIds.length > 0),
      map(({ accountIds }) => accountIds),
      fetchAccountsFromAccountIds(
        accountResource,
        legalEntityResource,
        'loadLastSelectedAccountsForBoUser'
      )
    ),
  { functional: true }
);

// we have a "last edited job ads" feature for BO user in the dashboard
// we need to display the name of the legal-entity so we're fetching the account
export const loadAccountsFromLastEditedVacancies = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityResource)
  ) =>
    actions$.pipe(
      ofType(vacancyApiActions.loadVacanciesByIdsSuccess),
      map(({ vacancies }) => vacancies.map(({ accountId }) => accountId)),
      fetchAccountsFromAccountIds(
        accountResource,
        legalEntityResource,
        'loadAccountsFromLastEditedVacancies'
      )
    ),
  { functional: true }
);

export const redirectOnUnSelectingWorkspace = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(boAuthActions.workspaceUnselected),
      tap(({ legalEntityId }) =>
        router.navigateByUrl(`/${AppConfig.routes.claimManagement}/${legalEntityId}`)
      )
    ),
  { functional: true, dispatch: false }
);

const fetchAccountsFromAccountIds = (
  accountResource: AccountResource,
  legalEntityResource: LegalEntityResource,
  errorNamespace: string
): OperatorFunction<string[], Action> =>
  pipe(
    map((accountIds) => removeDuplicates(accountIds as string[])),
    mergeMap((accountIds) =>
      accountResource.fetchByIds(accountIds).pipe(
        addLegalEntityToAccountsDto(legalEntityResource),
        map((accounts) => accountApiActions.loadAccountsSuccess({ accounts })),
        catchError((error: unknown) =>
          of(
            accountApiActions.loadAccountsFailure({
              error: getMessageError(error, errorNamespace),
            })
          )
        )
      )
    )
  );

const removeDuplicates = (accountIds: string[]): string[] => Array.from(new Set(accountIds));

const getLegalEntityAccountsQueryParams = (legalEntityId: string): Partial<QueryParams> => ({
  filter: `legalEntity.id==${legalEntityId}`,
  limit: 100,
  sort: `updatedAt|createdAt=desc`,
});
