import { filter, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Router, UrlTree } from '@angular/router';
import { selectWorkspaceSelecting } from '@mkp/auth/state';
import { LoadingState } from '@mkp/shared/util-state';
import { concatLatestFrom } from '@ngrx/operators';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { AppConfig } from '@config/app.config';
import { selectSelectedAccount } from '@mkp/account/state';
import { isNotNull } from './guard-helpers';

export const hasSelectedWorkspaceGuard: () => Observable<boolean | UrlTree> = () => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(selectWorkspaceSelecting).pipe(
    filter((state) => state === LoadingState.LOADED),
    concatLatestFrom(() => [
      store.select(selectIsBoUser).pipe(filter(isNotNull)),
      store.select(selectSelectedAccount),
    ]),
    map(([_, isBoUser, selectedAccount]) => getLoadedResult(router, !!selectedAccount, isBoUser))
  );
};

const getLoadedResult = (router: Router, hasSelectedAccount: boolean, isBoUser: boolean) => {
  return hasSelectedAccount || getUrlTree(router, isBoUser);
};

const getUrlTree = (router: Router, isBoUser: boolean): UrlTree =>
  router.createUrlTree([
    isBoUser ? AppConfig.routes.dashboard : AppConfig.routes.selectAccountMembership,
  ]);
