import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import { inject } from '@angular/core';
import { getMessageError } from '@core/models';
import {
  AccountMembershipMapper,
  AccountMembershipResource,
} from '@mkp/account-membership/data-access';
import { accountMembershipActions } from '@mkp/account-membership/actions';
import { authActions } from '@mkp/auth/actions';
import { legalEntityDetailsActions } from '@mkp/legal-entity/actions';
import { Account } from '@mkp/account/data-access';

export const loadAccountMemberships = createEffect(
  (actions$ = inject(Actions), accountMembershipResource = inject(AccountMembershipResource)) => {
    return actions$.pipe(
      ofType(accountMembershipActions.loadAccountMemberships),
      switchMap(({ query }) =>
        accountMembershipResource.listAccountMemberships(query).pipe(
          switchMap((accountMemberships) =>
            of(accountMembershipActions.loadAccountMembershipsSuccess({ accountMemberships }))
          ),
          catchError((error: unknown) =>
            of(
              accountMembershipActions.loadAccountMembershipsFailure({
                error: getMessageError(error, 'loadAccountMemberships'),
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const loadMoreAccountMemberships = createEffect(
  (actions$ = inject(Actions), accountMembershipResource = inject(AccountMembershipResource)) => {
    return actions$.pipe(
      ofType(accountMembershipActions.loadMoreAccountMemberships),
      switchMap(({ query }) =>
        accountMembershipResource.listPaginatedAccountMemberships(query).pipe(
          switchMap(({ accountMemberships, filter, _links, totalCount }) =>
            of(
              accountMembershipActions.loadMoreAccountMembershipsSuccess({
                accountMemberships,
                _links,
                filter,
                totalCount,
              })
            )
          ),
          catchError((error: unknown) =>
            of(
              accountMembershipActions.loadAccountMembershipsFailure({
                error: getMessageError(error, 'loadMoreAccountMemberships'),
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const loadAccountMembershipsOnLegalEntityDetails = createEffect(
  (actions$ = inject(Actions), accountMembershipResource = inject(AccountMembershipResource)) => {
    return actions$.pipe(
      ofType(legalEntityDetailsActions.accountsLoadSuccess),
      filter(({ accounts }) => accounts.length > 0),
      switchMap(({ accounts }) =>
        accountMembershipResource.listPaginatedAccountMemberships(getAccountsFilter(accounts)).pipe(
          switchMap(({ accountMemberships, totalCount }) =>
            of(
              legalEntityDetailsActions.accountMembershipsLoadSuccess({
                accountMemberships,
                totalCount,
              })
            )
          ),
          catchError((error: unknown) =>
            of(
              legalEntityDetailsActions.accountMembershipsLoadFailure({
                error: getMessageError(error, 'loadAccountMembershipsOnLegalEntityDetails'),
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const createAccountMembership = createEffect(
  (actions$ = inject(Actions), accountMembershipResource = inject(AccountMembershipResource)) => {
    return actions$.pipe(
      ofType(accountMembershipActions.createAccountMembership),
      switchMap(({ accountId, userId }) =>
        accountMembershipResource.createAccountMembership(accountId, userId).pipe(
          switchMap((accountMembership) =>
            of(accountMembershipActions.createAccountMembershipSuccess({ accountMembership }))
          ),
          catchError((error: unknown) =>
            of(
              accountMembershipActions.createAccountMembershipFailure({
                error: getMessageError(error, 'createAccountMembership'),
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const patchAccountMembershipState = createEffect(
  (actions$ = inject(Actions), accountMembershipResource = inject(AccountMembershipResource)) => {
    return actions$.pipe(
      ofType(accountMembershipActions.patchAccountMembershipState),
      switchMap(({ patch }) =>
        accountMembershipResource.patchAccountMembershipState(patch).pipe(
          switchMap((accountMembership) =>
            of(
              accountMembershipActions.patchAccountMembershipStateSuccess({
                id: accountMembership.id,
                changes: accountMembership,
              })
            )
          ),
          catchError((error: unknown) =>
            of(
              accountMembershipActions.patchAccountMembershipStateFailure({
                error: getMessageError(error, 'patchAccountMembershipState'),
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const initiateVerifiedUserAccountMemberships = createEffect(
  (
    actions$ = inject(Actions),
    accountMembershipResource = inject(AccountMembershipResource),
    mapper = inject(AccountMembershipMapper)
  ) => {
    return actions$.pipe(
      ofType(authActions.userIsVerified),
      switchMap(({ userId }) =>
        accountMembershipResource
          .getWithQuery(getActiveAccountMembershipsFilterForLoggedInUser(userId), { maxRetry: 4 })
          .pipe(
            map(({ _embedded, totalCount }) => ({
              accountMemberships: _embedded.results.map((dto) => mapper.fromJson(dto)),
              totalCount,
            })),
            map(({ accountMemberships, totalCount }) =>
              authActions.loadActiveAccountMembershipsSuccess({ accountMemberships, totalCount })
            ),
            catchError((error) =>
              getErrorStatus(error) === 403
                ? of(
                    // When failing to fetch the membership counts with the status 403, we assume
                    // they don't have any, and they need to be onboarded.
                    // If we keep this value to undefined we can't keep the user logged-in,
                    // and they won't have access to the claim-company page
                    authActions.loadActiveAccountMembershipsSuccess({
                      accountMemberships: [],
                      totalCount: 0,
                    })
                  )
                : of(
                    authActions.loadActiveAccountMembershipsFailed({
                      error: getMessageError(error, 'initiateVerifiedUserAccountMemberships'),
                    })
                  )
            )
          )
      )
    );
  },
  { functional: true }
);

const getErrorStatus = (error: unknown): number | undefined =>
  (error as { status: number })?.status;

const getActiveAccountMembershipsFilterForLoggedInUser = (userId: string) => ({
  filter: `user.id==${userId};state==ACTIVE`,
});

const getAccountsFilter = (accounts: Account[]) =>
  accounts.length
    ? { filter: accounts.map((account) => `account.id==${account.id}`).join(',') }
    : {};
