import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { selectIsDefault } from '@mkp/debug/state';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class TimingInterceptor implements HttpInterceptor {
  private readonly store = inject(Store);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const started = Date.now();
    return next.handle(req).pipe(
      concatLatestFrom(() => this.store.select(selectIsDefault)),
      tap(([event, shouldLog]) => {
        if (shouldLog && event instanceof HttpResponse) {
          const elapsed = Date.now() - started;
          console.log(`[TimingInterceptor] Request for ${req.urlWithParams} took ${elapsed} ms.`);
        }
      }),
      map(([event]) => event)
    );
  }
}
