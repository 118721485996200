import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { RootStoreModule } from '@app/store/root-store.module';
import { HttpLoaderFactory } from '@app/webpack-translate-loader';
import { AuthModule } from '@auth0/auth0-angular';
import { auth } from '@env/environment';
import { TrackingModule } from '@mkp/tracking/feature-tracking-old';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GraphQLModule } from '@shared/modules/graphql/graphql.module';
import { moduleImportGuard } from './helpers';

const auth0PasswordlessConfig = { ...auth, connection: '' }; // override connection so auth supports both 'Username-Password-Authentication' and 'email'

const modules = [
  BrowserAnimationsModule,
  GraphQLModule,
  AppRoutingModule,
  TrackingModule,
  RootStoreModule,
  AuthModule.forRoot(auth0PasswordlessConfig),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
];

@NgModule({
  imports: [modules],
  exports: [modules],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    moduleImportGuard(parentModule, 'CoreModule');
  }
}
